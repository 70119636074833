import { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

interface SectionProps {
  $bgColor: string;
  $bgPosition: string;
  $bgImage: string;
}

interface ButtonProps {
  $buttonColor: string;
  $buttonTextColor: string;
  $divideColor: string;
}

export const PossibilityBox = ({ manifestData }: any) => {
  const [boxData, setBoxData] = useState<any>();
  const box = manifestData?.['views']['possibilityBox'];
  const config = box?.['config'];

  useEffect(() => {
    const fetchBoxData = async () => {
      //This data is from page in the control panel
      try {
        const responsePage = await fetch(box.url);
        const dataPage = await responsePage.json();
        setBoxData(dataPage.data);
      } catch (e) {
        console.error(e);
      }
    };

    fetchBoxData();
  }, [box.url]);

  return (
    <Section
      $bgColor={config?.bgColor}
      $bgPosition={config?.bgPosition}
      $bgImage={boxData?.images[0].large}
    >
      <Content>
        <BoxHeader>{box?.title}</BoxHeader>
        <HeaderBorder $divideColor={config?.divideColor} />
        <BoxText dangerouslySetInnerHTML={{ __html: boxData?.content }} />
      </Content>
      {box?.external === true ? (
        <ExternalLink
          href={box.url}
          target="_blank"
          rel="noopener"
          $buttonColor={config?.buttonColor}
          $buttonTextColor={config?.buttonTextColor}
        >
          {config.buttonText}
        </ExternalLink>
      ) : (
        <StyledLink
          to={config?.feature}
          $buttonColor={config?.buttonColor}
          $buttonTextColor={config?.buttonTextColor}
        >
          {config.buttonText}
        </StyledLink>
      )}
    </Section>
  );
};

export const Section = styled.section<SectionProps>`
  display: flex;
  flex-direction: column;
  height: auto;
  align-items: center;
  justify-content: space-evenly;
  padding: 4rem 1rem;
  gap: 2rem;
  ${({
    $bgColor,
    $bgPosition,
    $bgImage,
  }) => `background: linear-gradient(transparent 50%, ${$bgColor} 50%),
  url(${$bgImage}) ${$bgPosition};`}
  @media screen and (min-width: 900px) {
    gap: 3rem;
  }
`;

export const Content = styled.div`
  height: auto;
  width: 100%;
  max-width: 700px;
  background-color: white;
  border-radius: 10px;
  padding: 1.5rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  gap: 5px;
`;

export const StyledLink = styled(Link)<ButtonProps>`
  height: 50px;
  width: 100%;
  max-width: 300px;
  color: ${({ $buttonTextColor }) => $buttonTextColor};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ $buttonColor }) => $buttonColor};
  border-radius: 30px;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1rem;

  &:hover {
    color: ${({ $buttonTextColor }) => $buttonTextColor};
    text-decoration: none;
  }
`;

export const ExternalLink = styled.a<ButtonProps>`
  height: 50px;
  width: 100%;
  max-width: 300px;
  color: ${({ $buttonTextColor }) => $buttonTextColor};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ $buttonColor }) => $buttonColor};
  border-radius: 30px;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1rem;

  &:hover {
    color: ${({ $buttonTextColor }) => $buttonTextColor};
    text-decoration: none;
  }
`;

export const BoxHeader = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  font-family: Fjalla One, sans-serif;
  text-transform: uppercase;
  @media screen and (min-width: 900px) {
    font-size: 1.875rem;
  }
`;

export const HeaderBorder = styled.hr<ButtonProps>`
  height: 2px;
  width: 70px;
  background-color: ${({ $divideColor }) => $divideColor};
`;

export const BoxText = styled.p`
  font-size: 0.8rem;
  margin-top: 0.5rem;
  max-width: 600px;
  @media screen and (min-width: 900px) {
    font-size: 1.1rem;
  }
`;
