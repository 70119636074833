import React from 'react';
import { Row, Col } from 'reactstrap';
import ocv from '../../../assets/OCV_LLC@2x.png';
import sheriff from '../../../assets/Sheriff_White.png';
import sheriffDark from '../../../assets/SheriffAppDark.png';
import ema from '../../../assets/Ema_logo_white.png';
import fire from '../../../assets/Fire_Long_V2.png';
import police from '../../../assets/Police_Long.png';
import publicHealth from '../../../assets/Public_Health_Long.png';
import { NavLink } from 'react-router-dom';
//import ListItem from "@material-ui/core/ListItem/ListItem";
import List from '@material-ui/core/List/List';
import apple from '../../../assets/apple.svg';
import google from '../../../assets/google-play-badge.png';
import SocialIcons from '../SocialIcons/SocialIcons';
import './Footer.css';

interface IVerticalMarket {
  [id: string]: {
    [id2: string]: any;
  };
}

export default function Footer(props: any) {
  // array that holds the app logos
  const appStore = [apple, google];
  // modify to include [footerConfig.vertical] and not just title
  const { classes, manifestData, footerConfig } = props;

  let verticalMarkets: IVerticalMarket = {
    sheriff: {
      href: 'https://thesheriffapp.com', // dark option to be handled elsewhere
      alt: 'The Sheriff App',
      img: manifestData['theme'] === 'dark' ? sheriff : sheriffDark,
    },
    police: {
      href: 'https://thepoliceapp.com',
      alt: 'The Police App',
      img: police,
    },
    ema: {
      href: 'https://myemaapp.com',
      alt: 'My EMA App',
      img: ema,
    },
    fire: {
      href: 'https://thefiredepartmentapp.com',
      alt: 'The Fire Department App',
      img: fire,
    },
    health: {
      href: 'https://thepublichealthapp.com',
      alt: 'The Public Health App',
      img: publicHealth,
    },
    ocv: {
      href: 'https://ocvapps.com',
      alt: 'OCV Apps',
      img: ocv,
    },
  };

  const SimpleFooter = (classes: any, manifestData: any, footerConfig: any) => (
    <Row
      className={classes.footer + ' ' + footerConfig?.additionalTailwindStyles}
    >
      <Col lg={1} md={3}>
        <img
          className={classes.footerIcon}
          src={
            manifestData['stylesheet'].images
              ? manifestData['stylesheet'].images[footerConfig.icon]['url']
              : ''
          }
          alt={
            manifestData['stylesheet'].images
              ? manifestData['stylesheet'].images[footerConfig.icon]['altText']
              : ''
          }
        />
      </Col>
      <Col
        className={classes.columnA}
        style={{ paddingLeft: 0, paddingRight: 0 }}
        lg={6}
        md={5}
      >
        <div className={classes.footerInfo}>
          <p className="footerInfo">{footerConfig.title}</p>
          <p
            className="footerInfo"
            dangerouslySetInnerHTML={{ __html: footerConfig.address }}
          />
          <p className="footerInfo">Phone: {footerConfig.phone}</p>
        </div>
      </Col>
      <Col
        className="columnB"
        style={{ paddingLeft: '20px', paddingRight: '20px' }}
        lg={5}
        md={4}
        sm={12}
      >
        <div className={classes.footerNav}>
          {footerConfig['menuItems'].map((item: string, index: number) =>
            index !== footerConfig['menuItems'].length - 1 ? (
              <NavLink
                key={item + index}
                className={classes.footerNavItem}
                to={'/' + item}
              >
                {manifestData['features'][item]['title'] + ' / '}
              </NavLink>
            ) : (
              <NavLink
                key={item + index}
                className={classes.footerNavItem}
                to={'/' + item}
              >
                {manifestData['features'][item]['title']}
              </NavLink>
            )
          )}
          <br />
          <h6 className="poweredBy">Powered by</h6>
          <a
            rel="noopener noreferrer"
            href={verticalMarkets[footerConfig.vertical].href}
            target={'_blank'}
          >
            <img
              className="footerImgOne"
              src={verticalMarkets[footerConfig.vertical].img}
              alt={verticalMarkets[footerConfig.vertical].alt}
            />
          </a>
        </div>
      </Col>
    </Row>
  );
  const ComplexFooter = (
    classes: any,
    manifestData: any,
    footerConfig: any
  ) => (
    <div className={footerConfig?.additionalTailwindStyles}>
      {footerConfig['phoneNumberCard'] && (
        <Row
          className="complexFooterRowOne"
          style={{
            background:
              manifestData['stylesheet']['colors'][
                footerConfig['phoneNumberCardBackground']
              ],
          }}
        >
          <Col xs={10} key="col1" className="pt-4">
            <Row className="grid grid-cols-1 sm:grid-cols-2">
              {footerConfig['phoneNumberCard'].map((phone: any, index: any) => (
                <Col key={phone.title + index} xs={12} sm={12} md={12}>
                  <div className="phoneNumberCardDiv h-full">
                    <p className="md:text-lg font-semibold mb-2">
                      {phone.title}
                    </p>
                    {phone['items'].map((item: any, index: any) => (
                      <p
                        key={item.name + index}
                        className="text-xs my-1 md:my-0 md:text-base"
                      >
                        {item.name + ' - '}
                        <strong>
                          <a
                            className="text-xs md:text-base"
                            href={'tel:' + item.number}
                            title={`Call us at ${item.number}`}
                          >
                            {item.number}
                          </a>
                        </strong>
                      </p>
                    ))}
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      )}
      <Row className={'complexFooterRowTwo bg-black text-white'}>
        <img
          className="footerImgTwo"
          src={
            manifestData['stylesheet'].images
              ? manifestData['stylesheet'].images[footerConfig.icon]['url']
              : ''
          }
          alt={
            manifestData['stylesheet'].images
              ? manifestData['stylesheet'].images[footerConfig.icon]['altText']
              : ''
          }
        />
        <Col className="footerColTwo" xs={10}>
          <p
            style={{
              letterSpacing: '1px',
              fontSize: '1.3em',
              fontFamily: 'Fjalla One, sans',
            }}
            className="pb-3"
          >
            {footerConfig.title}
          </p>
          <div className="flex justify-center">
            <a
              className="text-base pb-2 hover:text-white"
              target="_blank"
              rel="noopener noreferrer"
              title="Click to open on Google Maps"
              href={`http://maps.google.com/?q=${footerConfig?.address}`}
            >
              <p
                dangerouslySetInnerHTML={{
                  __html: footerConfig?.address.substring(
                    0,
                    footerConfig?.address.indexOf(',') + 1
                  ),
                }}
              />
              <p
                dangerouslySetInnerHTML={{
                  __html: footerConfig?.address.substring(
                    footerConfig?.address.indexOf(',') + 1
                  ),
                }}
              />
            </a>
          </div>
          <div className="flex justify-center">
            <a
              className="footerAnchorTwo hover:text-white"
              href={'tel:' + footerConfig.phone}
              title={`Call us at ${footerConfig.phone}`}
            >
              {footerConfig.phone}
            </a>
          </div>

          {footerConfig.businessHours && (
            <p className="">Hours: {footerConfig.businessHours}</p>
          )}
          <List className="footerList">
            {footerConfig['socialMedia']?.map((item: any) => (
              <SocialIcons
                key={item.title}
                props={item}
                divClasses={classes.footerSocialListItem + ' px-1'}
                linkClasses={classes.topSocialLink}
                imgClasses={'inline w-6'}
              />
            ))}
          </List>
          <List className="footerList flex flex-col sm:flex-row items-center justify-center">
            {footerConfig['socialMedia']?.map((item: any) =>
              appStore.find((logo: any) =>
                logo.includes(item?.title?.toLowerCase())
              ) ? (
                <li key={item?.title}>
                  <a
                    rel="noopener noreferrer"
                    target={'_blank'}
                    aria-label={item?.title}
                    className=""
                    href={item?.url}
                  >
                    <img
                      className={
                        item.title === 'apple'
                          ? 'w-40 max-h-14 sm:max-h-12'
                          : 'w-44'
                      }
                      src={appStore.find((logo: any) =>
                        logo.includes(item?.title?.toLowerCase())
                      )}
                      alt={`${item?.title} Logo`}
                    />
                  </a>
                </li>
              ) : null
            )}
          </List>
          <div className="flex flex-col md:flex-row justify-center">
            <p className="footerH6 my-auto">
              Copyright &copy;{new Date().getFullYear()}. Powered by
            </p>
            {'  '}
            <a
              rel="noopener noreferrer"
              href={
                verticalMarkets[footerConfig.vertical]
                  ? verticalMarkets[footerConfig.vertical].href
                  : verticalMarkets['ocv'].href
              }
              target={'_blank'}
              title="TheSheriffApp.com"
            >
              <img
                className="footerImgOne mx-auto"
                src={fire}
                alt={verticalMarkets[footerConfig.vertical].alt}
              />
            </a>
          </div>
        </Col>
      </Row>
    </div>
  );

  return (
    <div id={props.anchorID}>
      {footerConfig['subtype'] === 'simple' &&
        SimpleFooter(classes, manifestData, footerConfig)}
      {footerConfig['subtype'] === 'complex' &&
        ComplexFooter(classes, manifestData, footerConfig)}
    </div>
  );
}
