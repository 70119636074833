import styled from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName, library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { Link } from 'react-router-dom';

interface FeatureProps {
  title: string;
  feature: string;
  icon: IconName;
}

interface DivProps {
  bg?: string;
  bgColor: string;
  bgPosition: string;
}

export const FeatureButton = ({ manifestData }: any) => {
  library.add(fas);

  const colors = manifestData?.['stylesheet']['colors'];
  const config = manifestData?.['views']['featureBar2']['config'];

  if (!config) return <p>"Loading..."</p>;
  else {
    return (
      <Div
        bg={config?.bg}
        bgColor={config?.bgColor}
        bgPosition={config?.bgPosition}
      >
        <FeatureDiv>
          {config.items?.map(
            ({ feature, title, icon }: FeatureProps, index: number) => (
              <Feature colors={colors} to={feature} key={title + index}>
                <FontAwesomeIcon
                  icon={['fas', icon]}
                  fixedWidth={true}
                  size={'3x'}
                />
                <FeatureTitle>{title}</FeatureTitle>
              </Feature>
            )
          )}
        </FeatureDiv>
      </Div>
    );
  }
};

export const Div = styled.div<DivProps>`
  display: flex;
  justify-content: center;
  background: ${({ bg, bgColor }) =>
    `linear-gradient(${bgColor} 100%, ${bgColor} 100%), url(${bg})`};
  background-size: 100% 100%;
  padding: 2rem 0;
  background-position: ${({ bgPosition }) => bgPosition};
`;

export const FeatureDiv = styled.div`
  width: 865px;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  gap: 0.5rem;
  justify-content: center;
`;

export const Feature = styled(Link)<{ colors: any }>`
  text-transform: uppercase;
  font-family: Fjalla One, sans-serif;
  text-decoration: none;
  height: 125px;
  width: 125px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  border-radius: 10px;
  padding: 0.5rem;
  color: white;
  &:nth-child(odd) {
    background-color: ${({ colors }) => colors?.primary};
  }
  &:nth-child(even) {
    background-color: ${({ colors }) => colors?.tertiary};
  }

  &:hover {
    text-decoration: none;
    color: white;
  }
`;

export const FeatureTitle = styled.p`
  display: flex;
  align-items: center;
  height: 40px;
  font-size: 1rem;
  font-family: Fjalla One;
`;
